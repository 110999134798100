import React, { useContext, useEffect } from "react";
import { Button, Dropdown, Row, Col, Modal } from 'react-bootstrap'
import { GlobalContext } from "../contexts/global";
import { MdCalendarToday, MdMenu } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function TopBar() {

    const Navigate = useNavigate()

    const { conexao, loading, setLoading } = useContext(GlobalContext)

    return (
        <div className='topbar'>
            <div style={{ paddingLeft: 15 }}>
                <div style={{ display: 'flex', width: '100vw', alignItems: 'center' }}>
                    <div style={{ width: '50vw' }}>
                        Olá, {localStorage.getItem('Usuário Logado')}
                    </div>
                    <div style={{ width: '30vw', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="light"
                            style={{ marginLeft: 15, minWidth: 120 }}
                            onClick={() => {
                                localStorage.removeItem('Token')
                                localStorage.removeItem('Empresa')
                                localStorage.removeItem('email')
                                Navigate('/')
                            }}
                        >Sair
                        </Button>
                    </div>
                </div>
            </div>

            <Modal
                show={loading}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <Row>
                        <Col style={{ textAlign: 'center' }}>
                            Processando
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default TopBar