import React, { useEffect, useContext, useState } from "react";

import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";
import { GlobalContext } from "../../contexts/global";

import { Chart } from "react-google-charts";

import { Col, Row, Card, Modal, Form, Button } from 'react-bootstrap'

import { useNavigate } from "react-router-dom";

function Dashboard() {

    // const [loading, setLoading] = useState(false)

    const Navigate = useNavigate()

    const { conexao, timeSelecionado, periodo, modalPeriodo, setModalPeriodo, setPeriodo, loading, setLoading } = useContext(GlobalContext)

    const [indicadores, setIndicadores] = useState([])

    const [dataInicial, setDataInicial] = useState('')
    const [dataFinal, setDataFinal] = useState('')

    useEffect(() => {

        if (localStorage.getItem('email')) {
            var date = new Date();
            var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
            var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            GetDashboardStats(primeiroDia, ultimoDia)
            //eslint-disable-next-line
        } else {
            Navigate("/")
        }


    }, [timeSelecionado.id])


    useEffect(() => {

        if (localStorage.getItem('email')) {
            if (periodo?.id === 'mes_atual') {
                var date = new Date();
                var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
                var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                GetDashboardStats(primeiroDia, ultimoDia)

            } else

                if (periodo?.id === 'mes_anterior') {
                    var date = new Date();
                    var primeiroDia = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                    var ultimoDia = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0);

                    GetDashboardStats(primeiroDia, ultimoDia)
                }
        } else {
            Navigate("/")
        }




    }, [periodo])


    function GetDashboardStats(datainicial, datafinal) {

        setLoading(true)

        const url = conexao.endereco + '/dashboard/indicadores'

        let body = {
            "time": timeSelecionado.id,
            "p1": 60,
            "p2": 90,
            "p3": 120,
            "datainicial": datainicial.toISOString().split('T')[0],
            "datafinal": datafinal.toISOString().split('T')[0]
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setIndicadores(resposta)

            } else
                if (requisicao.status === 500) {
                    let resposta = JSON.parse(this.response.message)
                    if (resposta == 'Token inválido.') {
                        Navigate('/')
                    }

                }
        }

    }

    const departamentos = [
        ["Departamento", "Total", "Perdas", "Recuperados"],
        ["Mercearia", 50, 18, 32],
        ["Açougue", 76, 32, 44],
        ["Padaria", 28, 9, 19],
        ["Hortifruti", 30, 12, 18],
        ["Frios", 40, 22, 18],
        ["Congelados", 98, 28, 70],
        ["Lanchonete", 25, 2, 23],
    ]

    const times = [
        ["Time", "Total", "Perdas", "Recuperados"],
        ["Loja 001", 50, 18, 32],
        ["Loja 002", 76, 32, 44],
    ]

    const proximosVencimentos = [
        ["Período", "Volume", "Valor"],
        [indicadores.periodo1?.periodo + " Dias", indicadores.periodo1?.quantidade, indicadores.periodo1?.valor_number],
        [indicadores.periodo2?.periodo + " Dias", indicadores.periodo2?.quantidade, indicadores.periodo2?.valor_number],
        [indicadores.periodo3?.periodo + " Dias", indicadores.periodo3?.quantidade, indicadores.periodo3?.valor_number],
        ["+ " + indicadores.periodo4?.periodo + " Dias", indicadores.periodo4?.quantidade, indicadores.periodo4?.valor_number],

    ]

    const options_chart_departamentos = {
        animation: {
            startup: true,
            easing: "linear",
            duration: 500,
        },
        chartArea: { width: "50%" },
        hAxis: {
            title: "Evolução",
            minValue: 0,
        },
        vAxis: {
            title: "Departamento",
        },
    }
    const options_chart_times = {
        animation: {
            startup: true,
            easing: "linear",
            duration: 500,
        },

        chartArea: { width: "50%" },
        hAxis: {
            title: "Evolução",
            minValue: 0,
        },
        vAxis: {
            title: "Time",
        },

    }
    const options_chart_proximos_vencimentos = {
        animation: {
            startup: true,
            easing: "linear",
            duration: 500,
        },
        chartArea: { width: "70%" },
        bars: "horizontal",
        colors: ['green', 'rgb(14, 172, 235)',]
    }

    const fornecedores = [
        { "nome": "Nestle" },
        { "nome": "BRF" },
        { "nome": "Ambev" },
        { "nome": "Coca-Cola" },
    ]

    function Cartao(props) {
        return (
            <div className="cartao">
                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                    <Card.Header className="dashboard-cartao">{props.titulo}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {props.conteudo}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>

        )
    }

    function CartaoNumeros(props) {
        return (
            <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                <Card.Header className="dashboard-cartao">{props.titulo}</Card.Header>
                <Card.Body>
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <Col style={{ width: '50%', borderRight: '1px solid gray' }}>
                            <Card.Text style={{ textAlign: 'center' }}>
                                <strong style={{ fontSize: 28, color: props.cor }}>{props.numero}</strong>
                                <p style={{ color: 'grey' }}>Volume(s)</p>
                            </Card.Text>
                        </Col>
                        <Col style={{ width: '50%' }}>
                            <Card.Text style={{ textAlign: 'right' }}>
                                <strong style={{ fontSize: 18, color: props.cor }}>{props.valor}</strong>
                                <p style={{ color: 'grey', fontSize: 14 }}>Valor R$</p>
                            </Card.Text>
                            <Card.Text style={{ textAlign: 'right' }}>
                                <strong style={{ fontSize: 18, color: props.cor }}>{props.participacao}</strong>
                                <p style={{ color: 'grey', fontSize: 14 }}>Participação R$</p>
                            </Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    function CartaoProximosVencimentos(props) {
        return (
            <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                <Card.Header className="dashboard-cartao">{props.titulo}</Card.Header>
                <Card.Body>
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        height="130px"
                        data={proximosVencimentos}
                        options={options_chart_proximos_vencimentos}
                    />
                </Card.Body>
            </Card>
        )
    }

    return (
        <>
            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    {!modalPeriodo &&
                        <div className="conteudo">
                            <Row>
                                <Col lg={6}>
                                    <CartaoNumeros
                                        titulo="A Receber Hoje"
                                        numero={indicadores.identificados?.quantidade}
                                        valor={indicadores.identificados?.valor}
                                        participacao="100%"
                                        cor="rgb(14, 172, 235)"
                                    />
                                </Col>
                                <Col lg={6}>
                                    <CartaoNumeros
                                        titulo="A Pagar Hoje"
                                        numero={indicadores.recuperados?.quantidade}
                                        valor={indicadores.recuperados?.valor}
                                        participacao={parseFloat((indicadores.recuperados?.valor_number / indicadores.identificados?.valor_number) * 100).toFixed(2) + "%"}
                                        cor="green"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <CartaoNumeros
                                        titulo="Vendas de Hoje"
                                        numero={indicadores.pendentes?.quantidade}
                                        valor={indicadores.pendentes?.valor}
                                        participacao={parseFloat((indicadores.pendentes?.valor_number / indicadores.identificados?.valor_number) * 100).toFixed(2) + "%"}
                                        cor="rgb(14, 172, 235)"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <CartaoNumeros
                                        titulo="Vendas de Hoje"
                                        numero={indicadores.pendentes?.quantidade}
                                        valor={indicadores.pendentes?.valor}
                                        participacao={parseFloat((indicadores.pendentes?.valor_number / indicadores.identificados?.valor_number) * 100).toFixed(2) + "%"}
                                        cor="rgb(14, 172, 235)"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <CartaoNumeros
                                        titulo="Vendas de Hoje"
                                        numero={indicadores.pendentes?.quantidade}
                                        valor={indicadores.pendentes?.valor}
                                        participacao={parseFloat((indicadores.pendentes?.valor_number / indicadores.identificados?.valor_number) * 100).toFixed(2) + "%"}
                                        cor="rgb(14, 172, 235)"
                                    />
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Cartao
                                        titulo="Resultado por Departamento"
                                        conteudo={
                                            <Chart
                                                chartType="BarChart"
                                                width="100%"
                                                height="450px"
                                                data={departamentos}
                                                options={options_chart_departamentos}
                                            />
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Cartao
                                        titulo="Ranking Fornecedores com Maior Índice de Perdas"
                                        conteudo={
                                            fornecedores.map(item => {
                                                return (
                                                    <p>{item.nome}</p>
                                                )
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Cartao
                                        titulo="Últimas Ações Realizadas"
                                        conteudo={
                                            fornecedores.map(item => {
                                                return (
                                                    <p>{item.nome}</p>
                                                )
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            </div>

            <Modal
                show={modalPeriodo}
                onHide={() => { setModalPeriodo(false) }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Período</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Data Inicial</Form.Label>
                                <Form.Control size="sm" type="date" onChange={(e) => { setDataInicial(e.target.value) }} value={dataInicial} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Data Final</Form.Label>
                                <Form.Control size="sm" type="date" onChange={(e) => { setDataFinal(e.target.value) }} value={dataFinal} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => { setModalPeriodo(false) }}>
                        Fechar
                    </Button>
                    <Button variant="dark" onClick={() => {

                        var dtI = new Date(dataInicial);
                        var dtF = new Date(dataFinal);

                        const data_inicio_us = dtI.toISOString().split('T')[0]
                        const data_final_us = dtF.toISOString().split('T')[0]

                        const data_inicio_format = data_inicio_us.substring(8, 10) + '/' + data_inicio_us.substring(5, 7) + '/' + data_inicio_us.substring(0, 4)
                        const data_final_format = data_final_us.substring(8, 10) + '/' + data_final_us.substring(5, 7) + '/' + data_final_us.substring(0, 4)


                        GetDashboardStats(dtI, dtF)
                        setPeriodo({ ...periodo, nome: data_inicio_format + ' a ' + data_final_format, id: 'custom' })
                        setModalPeriodo(false)
                        // alert(primeiroDia.toLocaleDateString("pt-BR"))
                        // alert(ultimoDia.toLocaleDateString("pt-BR"))
                    }}>Aplicar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Dashboard