import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { MdSettings } from 'react-icons/md'
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../../contexts/global";

import { Col, Row, Card, Modal, Form, Button, Breadcrumb, ButtonGroup } from 'react-bootstrap'

function TelaLicenciamento() {

    const { conexao, setLoading } = useContext(GlobalContext)

    const [licencas, setLicencas] = useState([])

    const [modalLicenca, setModalLicenca] = useState(false)
    const [edicao, setEdicao] = useState(false)

    const [licencaAtual, setLicencaAtual] = useState({})

    const Navigate = useNavigate()

    useEffect(() => {
        GetLicencas()
    }, [])

    function GetLicencas() {

        setLoading(true)

        const url = conexao.endereco + '/licencas/listar'

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify())

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setLicencas(resposta)
                setLoading(false)
            }
        }
    }

    function AtualizarLicenca() {

        setLoading(true)

        const url = conexao.endereco + '/licencas/atualizar'

        let body = {
            "id": licencaAtual.lic_id,
            "usuario": licencaAtual.lic_usuario,
            "qtde_times": licencaAtual.lic_qtde_times,
            "qtde_membros": licencaAtual.lic_qtde_membros,
            "status": 'A',
            "plano": licencaAtual.lic_plano,
            "recorrencia": licencaAtual.lic_recorrencia,
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            setModalLicenca(false)
            if (requisicao.status !== 200) {
                let resposta = JSON.parse(this.response)
                alert(resposta.message)
                setLoading(false)
            } else {
                GetLicencas()
            }
        }
    }

    function CriarLicenca() {

        setLoading(true)

        const url = conexao.endereco + '/licencas/criar'

        let body = {
            "usuario": licencaAtual.lic_usuario,
            "qtde_times": licencaAtual.lic_qtde_times,
            "qtde_membros": licencaAtual.lic_qtde_membros,
            "status": "A",
            "plano": licencaAtual.lic_plano,
            "recorrencia": licencaAtual.lic_recorrencia,
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            setModalLicenca(false)
            if (requisicao.status !== 200) {
                let resposta = JSON.parse(this.response)
                alert(resposta.message)
                setLoading(false)
            } else {
                GetLicencas()
            }
        }
    }

    function RemoverLicenca(dados) {

        setLoading(true)

        const url = conexao.endereco + '/licencas/remover'

        let body = {
            "id": licencaAtual.lic_id
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            setModalLicenca(false)
            if (requisicao.status !== 200) {
                let resposta = JSON.parse(this.response)
                alert(resposta.message)
                setLoading(false)
            } else {
                GetLicencas()
            }
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Usuário',
            selector: row => row.lic_usuario,
            sortable: true,
            cellExport: row => row.lic_usuario,
        },
        {
            name: 'Plano',
            selector: row => row.lic_plano,
            sortable: true,
            maxWidth: '120px',
            cellExport: row => row.lic_plano
        },
        {
            name: 'Recorrência',
            selector: row => row.lic_recorrencia,
            sortable: true,
            maxWidth: '150px',
            cellExport: row => row.lic_recorrencia,
        },
        {
            name: 'Qtde Times',
            selector: row => row.lic_qtde_times,
            sortable: true,
            maxWidth: '150px',
            cellExport: row => row.lic_qtde_times,
        },
        {
            name: 'Qtde Membros',
            selector: row => row.lic_qtde_membros,
            sortable: true,
            maxWidth: '150px',
            cellExport: row => row.lic_qtde_membros,
        },
        {
            name: 'Status',
            selector: row => row.lic_status,
            sortable: true,
            center: true,
            maxWidth: '60px',
            cellExport: row => row.lic_status,
        }
    ];

    const tableData = {
        columns: colunas,
        data: licencas,
        filterPlaceholder: 'Filtrar',
        exportHeaders: true
    };

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active>Clientes</Breadcrumb.Item>
                                <Breadcrumb.Item active>Licenciamento</Breadcrumb.Item>
                            </Breadcrumb>

                        </Row>
                        <Row>
                            <Col lg={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                    <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white', marginRight: 0 }} onClick={() => {
                                        setEdicao(false)
                                        setLicencaAtual({})
                                        setModalLicenca(true)
                                    }}>Incluir</Button>
                                    {/* <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white' }} onClick={() => {
                                        // Navigate('/cadastro-produtos/inclusao')
                                    }}>Pesquisar</Button> */}
                                </ButtonGroup>
                            </Col>
                            {/* <Col lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                    <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white' }} onClick={() => {
                                        Navigate('/cadastro-produtos/inclusao')
                                    }}>Classificação de Produtos</Button>
                                </ButtonGroup>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao">Licenças de Uso</Card.Header>
                                    <Card.Body>
                                        <DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={colunas}
                                                data={licencas}
                                                noHeader
                                                onRowClicked={(row, index) => {
                                                    setEdicao(true)
                                                    setLicencaAtual(row)
                                                    setModalLicenca(true)
                                                }}
                                                striped={true}
                                                highlightOnHover={true}
                                                responsive={true}
                                                pointerOnHover={true}
                                                noDataComponent={"Nenhum Registro Encontrado =/"}
                                                pagination={true}
                                                paginationComponentOptions={paginationComponentOptions}
                                            />
                                        </DataTableExtensions>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>


            <Modal
                show={modalLicenca}
                onHide={() => { setModalLicenca(false) }}
                backdrop="static"
                keyboard={false}
            // dialogClassName={"modal-50"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Licenciamento de Usuário</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Usuário / E-mail</Form.Label>
                                <Form.Control size="sm" type="email" onChange={(e) => { setLicencaAtual({ ...licencaAtual, lic_usuario: e.target.value }) }} value={licencaAtual?.lic_usuario} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Plano</Form.Label>
                                <Form.Select size="sm" onChange={(e) => { setLicencaAtual({ ...licencaAtual, lic_plano: e.target.value }) }} value={licencaAtual?.lic_plano}>
                                    <option>Selecione</option>
                                    <option value="BASICO">BASICO</option>
                                    <option value="PRO">PRO</option>
                                    <option value="COMPLETO">COMPLETO</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Recorrência</Form.Label>
                                <Form.Select size="sm" onChange={(e) => { setLicencaAtual({ ...licencaAtual, lic_recorrencia: e.target.value }) }} value={licencaAtual?.lic_recorrencia}>
                                    <option>Selecione</option>
                                    <option value="MENSAL">MENSAL</option>
                                    <option value="TRIMESTRAL">TRIMESTRAL</option>
                                    <option value="SEMESTRAL">SEMESTRAL</option>
                                    <option value="ANUAL">ANUAL</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Qtde Times</Form.Label>
                                <Form.Control size="sm" type="number" onChange={(e) => { setLicencaAtual({ ...licencaAtual, lic_qtde_times: e.target.value }) }} value={licencaAtual?.lic_qtde_times} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Qtde Membros</Form.Label>
                                <Form.Control size="sm" type="number" onChange={(e) => { setLicencaAtual({ ...licencaAtual, lic_qtde_membros: e.target.value }) }} value={licencaAtual?.lic_qtde_membros} />
                            </Form.Group>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    {edicao &&
                        <Button variant="warning" onClick={() => {

                            RemoverLicenca()

                        }}>Remover Licença</Button>
                    }
                    <Button variant="light" onClick={() => { setModalLicenca(false) }}>
                        Fechar
                    </Button>
                    {!edicao &&
                        <Button variant="dark" onClick={() => {

                            setModalLicenca(false)
                            CriarLicenca()
                            // alert(JSON.stringify(licencaAtual))

                        }}>Gerar Licença</Button>
                    }
                    {edicao &&
                        <Button variant="dark" onClick={() => {

                            AtualizarLicenca()

                        }}>Atualizar Licença</Button>
                    }
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default TelaLicenciamento