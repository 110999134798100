import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GlobalProvider from './contexts/global'

import Dashboard from './pages/Dashboard';
import TelaLogin from './pages/Login';

import InclusaoProduto from './pages/Cadastros/produto-inclusao';

import TelaPropostas from './pages/Propostas';
import TelaLicenciamento from './pages/Licenciamento';

function App() {
  return (

    <GlobalProvider>

      <BrowserRouter>

        <Routes>
          <Route exact path="/" element={<TelaLogin />} />

          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/clientes/licenciamento" element={<TelaLicenciamento />} />
          <Route exact path="/cadastro-produtos/inclusao" element={<InclusaoProduto />} />


          <Route exact path="/comercial/propostas" element={<TelaPropostas />} />

        </Routes>

      </BrowserRouter>

    </GlobalProvider>
  );
}

export default App;
